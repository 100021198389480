const zh = {
    alert:{
        reLogin: 'ທີ່ຢູ່ມີການປ່ຽນແປງ, ກະລຸນາເຂົ້າສູ່ລະບົບໃໝ່',
        copied: 'ຄັດລອກສຳເລັດແລ້ວ',
        connectWallet: 'ກະລຸນາເຊື່ອມກະເປົາເງິນ',
        notBound: 'ທີ່ຢູ່ອ້າງອີງຍັງບໍ່ໄດ້ຜູກມັດ, ກະລຸນາຜູກມັດ',
        inputParent: 'ກະລຸນາໃສ່ທີ່ຢູ່ອ້າງອີງ',
        addressError: 'ກະລຸນາໃສ່ທີ່ຢູ່ກະເປົາເງິນທີ່ຖືກຕ້ອງ',
        parentError: 'ທີ່ຢູ່ອ້າງອີງບໍ່ຖືກຕ້ອງ, ກະລຸນາໃສ່ໃໝ່',
        boundSuccess: 'ຜູກມັດສຳເລັດແລ້ວ',
        boundFailed: 'ຜູກມັດບໍ່ສຳເລັດ, ກະລຸນາລອງໃໝ່',
        success: 'ສຳເລັດ',
        failed: 'ບໍ່ສຳເລັດ',
        soon: 'ຈະເປີດໃນບໍ່ດົນນີ້...',
        insufficient: 'ຍອດເງິນບໍ່ພຽງພໍ',
        quotaInsufficient: 'ໂຄຕາບໍ່ພຽງພໍ',
        amountError: 'ຈຳນວນຜິດພາດ',
        minAmountError: 'ຈຳນວນຂັ້ນຕ່ຳ',
        redeemed: 'ຄຳສັ່ງບໍ່ມີຢູ່ຫລືໄດ້ຖືກໄຖ່ແລ້ວ',
        enter: 'ຢືນຢັນ',
        congratulation: 'ຂໍແສດສະຫຼີ, ທ່ານໄດ້ສຳເລັດແລ້ວ',
        walletInsufficient: 'ຍອດເງິນກະເປົາບໍ່ພຽງພໍ',
        copySuccess: 'ຄັດລອກສຳເລັດແລ້ວ'
    },
    header: {
        link: 'ເຊື່ອມກະເປົາເງິນ',
        home: 'ໜ້າຫຼັກ',
        lp: 'ການຝາກ',
        award: 'ລາງວັນ',
        solfi: 'SOLFI',
        user: 'ບັນຊີ',
        ido: 'IDO',
        inc: '© 2024 SolFi Inc. ສິດທິທັງໝົດສະຫຼອງ.',
        bind: 'ຜູກມັດ',
        bindPla: 'ກະລຸນາໃສ່ທີ່ຢູ່ອ້າງອີງ',
        bindTitle: 'ກະລຸນາຜູກມັດທີ່ຢູ່ອ້າງອີງ'
    },
    home:{
        launchTitle: 'Sol.fi ແມ່ນ',
        launchTitle1: 'ຫ້ອງສະໝຸດກະລຸຍຸດ DeFi ອັດຕະໂນມັດ',
        launchTitle2: 'ທີ່ມຸ່ງຫມາຍໃຫ້ການບໍລິການການເງິນທີ່ມີປະສິດທິພາບແລະປອດໄພສຳລັບຜູ້ໃຊ້ຢູ່ blockchain Solana.',
        launchDesc: 'ພວກເຮົາມຸ່ງຫມາຍທີ່ຈະສ້າງລະບົບເງິນອີເລັກທໍາທີ່ເປີດແລະມີຄວາມຫລາກຫລາຍ, ໃຫ້ຜູ້ໃຊ້ສາມາດໃຊ້ງານໄດ້ຢ່າງງ່າຍດາຍໃນການຝາກແລະການຄ້າໄດ້ຫລາຍປະເພດຂອງສິນທີ່ດີ.',
        but1: 'ຝາກ',
        but2: 'ເອກະສານ',
        diversifiedTitle0: 'ງ່າຍ',
        diversifiedTitle10: 'ຫລາກຫລາຍ',
        diversifiedDesc0: 'Sol.fi ໃຫ້ການແກ້ໄຂທີ່ຄົບຄື້ນສຳລັບການຝາກ SOL ແລະການຄ້າທີ່ບໍ່ມີຄວາມສະດວກ, ໃຫ້ຜູ້ໃຊ້ສາມາດເຂົ້າຮ່ວມກິດຈະກຳຂອງ DeFi ໄດ້ຫລາກຫລາຍໂດຍບໍ່ຕ້ອງຈັດການທີ່ຊັບຊ້ອນ.',
        diversifiedTitle1: 'ການເງິນ',
        diversifiedTitle11: 'ທີ່ບໍ່ມີຄວາມສະດວກ',
        diversifiedDesc1: 'ເປັນສ່ວນຫນຶ່ງຂອງ blockchain Solana, Sol.fi ໄດ້ສ້າງການເງິນທີ່ບໍ່ມີຄວາມສະດວກທີ່ຄົບຄື້ນ, ໃຫ້ຄວາມປອດໄພແລະປະສິດທິພາບການຄ້າໃນມືຂອງຜູ້ໃຊ້ດ້ວຍຄ່າທີ່ຕ່ຳກວ່າສຳລັບການຝາກ.',
        diversifiedTitle2: 'ການສະໜັບສະໜູນການປະຕິບັດ',
        diversifiedTitle12: 'ຫລາກຫລາຍ',
        diversifiedDesc2: 'ຕອນນີ້ Sol.fi ໄດ້ປະຕິບັດການຝາກກັບສະເທີນທີ່ມີຄວາມຢືດຢຸ່ນ, Raydium ແລະ Orca, ແຕ່ໃນອະນາຄົດພວກເຮົາຈະເພີ່ມການສະໜັບສະໜູນຕົວເລືອກການລົງທຶນຂອງ DeFi ທີ່ມີປະສິດທິພາບແລະເຂົ້າໄດ້ງ່າຍ.',
        benefit: 'ຜົນປະໂຫຍດຫລັກ',
        benefitTitle0: 'ກໍາໄລສູງ',
        benefitText0: 'ຜູ້ໃຊ້ສາມາດທໍາກໍາໄລສູງໂດຍການສະແດງຊື້ເຄື່ອງ SOL ແລະເພີ່ມຄວາມຄຸ້ມຄ່າໃຫ້ກັບຊື້ເຄື່ອງ.',
        benefitTitle1: 'ການລົງທຶນແບບຫຼາຍຮູບແບບ',
        benefitText1: 'Sol.fi ເຮັດໃຫ້ຜູ້ໃຊ້ສາມາດຂໍ້ມູນການສະແດງຄືນເພື່ອຮັບຄ່າຄືນຢ່າງຫຼາຍຮູບແບບແລະລົງທຶນແບບຄອບຄອບ, ເພື່ອປ້ອງກັນຄວາມເສຍຫາຍ.',
        benefitTitle2: 'ລະບົບນິເດຂໍ້ມູນເປີດກວ້າງ',
        benefitText2: 'ເປັນສ່ວນໜຶ່ງຂອງລະບົບນິເດເປີດກວ້າງ, Sol.fi ຈະຍືດຂະຫຍາຍແລະເພີ່ມການເຮັດງານທີ່ດີຫລາຍກວ່ານີ້ໃນຂອບເຂດ DeFi ເພື່ອໃຫ້ຜູ້ໃຊ້ມີບໍລິການທາງການເງິນທີ່ກວ້າງຂວາງແລະໂອກາດການກໍາໄລທີ່ຫຼາຍຂຶ້ນ.',
        friend: 'ຫມູ່ຄ່າມີໄຊ',
        friendDesc: 'ຍຸດທະສາດການສະແດງຄືນຂອງ Sol.fi ໄດ້ຮັບການປະຕິບັດເພື່ອສະໜັບສະໜູນໂດຍ Seven Seas, ຜູ້ກໍາລັງເປັນໜຶ່ງໃນຜູ້ສ້າງທີ່ເກັ່ງໃນຂອງລະບົບສະແດງຂອງ DeFi. ໂດຍການຮ່ວມມືກັບ Seven Seas, Sol.fi ສາມາດຈະເປັນໄວ້ສະແດງຄືນທີ່ປອດໄພແລະທີ່ນ່າເຊື່ອໃຈໃຫ້ກັບຜູ້ໃຊ້ແລະຍັງປັບປຸງເພື່ອຂະເຫຍາຍສະແດງຄືນໄດ້ກໍາໄລຫລາຍຂຶ້ນ. ຜ່ານ Sol.fi, ພວກເຮົາຫວັງວ່າຈະເຮັດເປັນສ່ວນໜຶ່ງໃນການພັດທະນາລະບົບ Solana ໃຫ້ເຂົ້າເຂດໄກລ້າງແລະໃຫ້ກຽດທີ່ເປັນຄວາມປອດໄພໃນລະບົບແລະທຸລະກິດທີ່ເຄື່ອນໄຫວໃຫມ່ຂອງບລອກເຊນ.'
    },
    pay: {
        desc: 'ຮັກສາອະນາຄົດຂອງທ່ານໃນ Solana – ກະເປົາເງິນທີ່ທ່ານໄວ້ວາງໃຈໄດ້ ເຊິ່ງສາມາດເຮັດທຸລະກໍາໄດ້ໄວຄືກັບຟ້າຜ່າ! ',
        days: 'ວັນ',
        hours: 'ຊົ່ວໂມງ',
        minutes: 'ນາທີ',
        seconds: 'ວິນາທີ',
        android: 'ດາວໂຫລດ Android',
        ios: 'ດາວໂຫລດໃນ App Store'
    },
    lp:{
        title: 'ສະແດງ SOL ບໍ່ເປັນເວລາ',
        title1: 'SolFi ຈະໃຫ້ການສະແດງ SOL ງ່າຍຂຶ້ນ ສໍາລັບນັກລົງທຶນແລະສະມາຄົມ ໃຫ້ຄ່າເພີ່ມເຕີມແລະຜົນກໍ່ອະທິບາຍສູງ.',
        stackDesc: 'SolFi ຈະໃຫ້ການສະແດງ SOL ງ່າຍຂຶ້ນ ສໍາລັບນັກລົງທຶນແລະສະມາຄົມ ໃຫ້ຄ່າເພີ່ມເຕີມແລະຜົນກໍ່ອະທິບາຍສູງ.',
        stackList1: 'ການສະແດງຂອງຂໍ້ມູນ',
        stackList2: 'ສໍາເລັດການສະແດງ',
        stackList3: 'ສໍາເລັດການເປັນຜົນກະດິບ',
        lp: 'ສະແດງ',
        underway: 'ສະແດງຢູ່',
        earn: 'ສໍາເລັດຜົນກະດິບ',
        yet: 'ສາມາດອອກຜົນກະດິບ',
        redeem: 'ຮັບຄືນ',
        yetBut: 'ອອກຜົນກະດິບ',
        accredit: 'ອະນຸຍາດ',
        record: 'ບັນທຶກສະແດງ',
        tokenTitle: 'ຜົນກະດິບສໍາເລັດ',
        tokenDesc: 'ບໍ່ອອກຜົນກະດິບຈະເພີ່ມຜົນກະດິບສໍາເລັດ ຕາມອັດຕາ 1SOLFI = 1SOL ຈະແບ່ງແບບ SOLFI ດິບ. SolFi Token (SOLFI) ຈະເປັນປະລະດູມຟັງຊັນຂອງໂປລະຟໍດິບ ສາມາດບວກປະສົບກັບບໍລິການ AI ແລະສະມາຄົມ ໃນອະນາຄົດຈະສູງເລີຍ.',
        tokenList1: 'ສາມາດປ່ອນຜົນກະດິບສໍາເລັດ',
        tokenList2: 'ສໍາເລັດຜົນກະດິບສໍາເລັດ',
        tokenList3: 'ສາມາດອອກຜົນກະດິບສໍາເລັດ',
        yes: 'ແມ່ນ',
        no: 'ບໍ່ແມ່ນ',
        back: 'ກັບໄປສະແດງ',
        money: 'ເງິນ',
        timeStart: 'ເວລາເລີ່ມສະແດງ',
        timeEnd: 'ເວລາສິ້ນສຸດ',
        cancel: 'ຍົກເລີກສະແດງອັດໂຕເມັດ',
        more: 'ເພີ່ມເຕີມ...',
        max: 'ສູງສຸດ',
        quotaRemain: 'ຄ່າເລົ່າ',
        usable: 'ສາມາດສະແດງ',
        redeemed: 'ຮັບຄືນແລ້ວ',
        redeeming: 'ກໍ່ຮັບຄືນ...',
        staking: 'ສະແດງຢູ່...'
    },
    award:{
        communityDesc: 'ສະມາຄົມສະໝັກສໍາເລັດເພື່ອສະໜັບສະໜູນການພັດທນາສະມາຄົມ, ຈະເພີ່ມຂຶ້ນຄະແນນສະໜັບສະໜູນສະເພາະສະມາຄົມ.',
        communityList1: 'ສໍາເລັດຄະແນນ',
        communityList2: 'ສະເພາະອອກ',
        communityList3: 'ສາມາດອອກ',
        communityBut: 'ອອກ',
        record: 'ບັນທຶກຄະແນນ',
        listType1: 'ຄະແນນສໍາເລັດສະແດງ',
        listType2: 'ຄະແນນທມະຄົມ',
        listType3: 'ຄະແນນລະດັບດຽວກັນ',
        listType4: 'ຄະແນນບໍໍລິມາດ'
    },
    top:{
        tokenDesc: 'SolFi Token (SOLFI) ຈະເປັນປະລະດູມຟັງຊັນຂອງໂປລະຟໍດິບ, ສາມາດບວກປະສົບກັບບໍລິການ AI ແລະສະມາຄົມ ໃນອະນາຄົດຈະສູງເລີຍ.',
        price: 'ລາຄາ',
        allAmount: 'ຈຳນວນສົງເສີມ',
        market: 'ຕະລາງຕະຫລອດສະໄໝ',
        my: 'SOLFI ຂອງຂໍ້ມູນ',
        balance: 'ຄວາມບັນທຶກໃນກະແສ',
        buy: 'Swap',
        approve: 'ອະນຸຍາດ SOL',
        approveTop: 'ອະນຸຍາດ SOLFI',
        earn: 'ສໍາເລັດຜົນກະດິບ',
        getYet: 'ສາມາດຮັບບໍໍ',
        get: 'ຮັບ'
    },
    ido:{
        turn: 'ເວລາປະຈຸບັນ',
        all: 'ລວມທົ່ວໄປ IDO',
        price: 'ລາຄາ',
        limit: 'ຂອບແຂວງສະໝັກ',
        ps: 'ວິທີປົດເປີດ: ປ່ອນໃຫ້ປິດບົດ[]ເດືອນ ແລະຈິບອອກໃນ 12 ເດືອນ',
        my: 'ຂອບແຂວງຂອງຂໍ້ມູນ',
        nobuy: 'ບໍ່ເສີມ',
        balance: 'ບັນທຶກ',
        custom: 'ຄາດຄະເນຈະເພີ່ມເອີກ',
        buy: 'ສະໝັກປັດຈຸບັນ',
        buyYet: 'ສະໝັກແລ້ວ',
        record: 'ບັນທຶກສະໝັກ',
        buyTrun: 'ເວລາສະໝັກ',
        buyUsdt: 'ສະໝັກ SOL',
        getTop: 'ເພີ່ມ SOLFI',
        unlock: 'ເວລາເປີດບັນທຶກສໍາເລັດ',
        unlockYet: 'ເປີດບັນທຶກ SOLFI ແລ້ວ',
        claimable: 'ສາມາດຮັບຄະແນນ SOLFI',
        get: 'ຮັບ',
        nodataText: 'ບໍ່ມີບັນທຶກສະໝັກ'
    },
    user:{
        desc: 'ແບ່ງປັນ SolFi, ຊ່ອຍເພີ່ມຜູ້ຕ້ອງການເປັນຈິງ, ໃນສໍາລັບພະລະງານເລີມລາຄາສູງໃນສະລະແບບບລອກ່ວຍການລາຄາສູງ.',
        joinTime: 'ເວລາລົງທະບຽນ',
        memberTeam: 'ສະມາຄົມທີມ',
        memberDirect: 'ສະມາຄົມແບບສະແດງ',
        teamLp: 'ສະແດງທີມ',
        myLink: 'ເຊື່ອງທໍ່ອອກຂອງຂໍ້ມູນ',
        myTeam: 'ທີມຂອງຂໍ້ມູນ',
        personage: 'ສະແດງສໍາເລັດ',
        cur: 'ປະຈຸບັນ',
        lookDown: 'ເບິ່ງລະດັບລຸ່ມ',
        copy: 'ຄອບປ່ອນ',
        info: 'ຂໍໍລວມ'
    },
    idos:{
        title: 'SolFi ອິຄົມພັກສິບກິດເຮັບ',
        total: 'ລວມ',
        type1: 'ຊຸດລົງວຽກ',
        type2: 'ລາຍຊົງຫຼືຄົງເອົາ',
        type1Time: '2024 ປີ 12 ເດືອນ 03 ວັນ 8:00 ຄໍາ',
        price: 'ລາຄາ',
        amount: 'ຈຳນວນ',
        unit: '{amount} ຊຸດ',
        type2Time: '2024 ປີ 12 ເດືອນ 04 ວັນ 8:00 ຄໍາ',
        advantageTitle1: 'ກະຊວງຜິດ',
        advantageDesc1: '10%ຈະອອກໃນ24ຊົງຫົວເມືອນ, ແຕ່ຫຼັງຈາກມື້ແກ່ຂຶ້ນເປົາ',
        advantageTitle2: 'ຄຸມໃນບລີນຂົດ',
        advantageDesc2: 'ກຸ່ມຄົບບົດ',
        advantageTitle3: 'ກອງພາຍເພິກໃຊ້',
        advantageDesc3: 'ເງິນແຜລກ KOLຊິດເພິກ 2 SOL',
        ido: 'ທີ່ຢູ່ຮັບເງິນທາງການຄ້າ IDO (ທີ່ຢູ່ Solana)',
        descTitle1: 'ການຄໍາຂຽນ 40%',
        desc1: 'ງົບກຳນົດທີ່ຈຳກວານຍອດ',
        descTitle2: 'ທີມງານບໍລິຫານ 20%',
        desc2: 'ຮັບປະກັນວ່າທີມງານທີ່ມີຄວາມຮັກສະເຫົາຈະມີທັບທີງານທີ່ມີທຸລະກຳເພືອຮັບປະກັນວ່າ SolFi ເຮັບງານຢ່າງລາວແລະພັດທະນາຕໍ່ໄປ',
        descTitle3: 'ຄະນະທະຫານການຊົດ 20%',
        desc3: 'ຮັບປະກັນວ່າຄະນະທະຫານການຊົດດຳເນີນງານຢ່າງຫຼວງແລະມີປະສົບຜົນງານ',
        descTitle4: 'ກິດຈະກຳຊຸມຊົນ 10%',
        desc4: 'ທຶນເຫົາຮູ້ຮອຍວັງຕົວກິດຈະກຳຊຸມຊົນກຳລັງປະຕິດຕິດຕົວ',
        descTitle5: 'SolFiກັບປະສົບຜົງ 5%',
        desc5: 'ແຫຼກປະທິດຫົວເຫົາທົບ ทທจำลบ',
        descTitle6: 'SolFi ທຶນພັດທະນາຊຸມຊົນ 5%',
        desc6: 'ມຸ່ງຫາເພືອສົ່ງສົງງານໃນຄົບທັບພາຍໃນຈະຮອງເມື່ອນການເຮັບງານ'

    }
};

export default zh;