const th = {
    alert:{
        reLogin: 'เปลี่ยนแปลงที่อยู่ โปรดเข้าสู่ระบบใหม่',
        copied: 'คัดลอกสำเร็จ',
        connectWallet: 'โปรดเชื่อมต่อกระเป๋าเงิน',
        notBound: 'ยังไม่ได้เชื่อมโยงที่อยู่ผู้แนะนำ โปรดเชื่อมโยง',
        inputParent: 'กรุณาใส่ที่อยู่ผู้แนะนำ',
        addressError: 'กรุณาใส่ที่อยู่กระเป๋าเงินที่ถูกต้อง',
        parentError: 'ที่อยู่ผู้แนะนำไม่ถูกต้อง กรุณาใส่ใหม่',
        boundSuccess: 'เชื่อมโยงสำเร็จ',
        boundFailed: 'เชื่อมโยงไม่สำเร็จ โปรดลองอีกครั้ง',
        success: 'สำเร็จ',
        failed: 'ไม่สำเร็จ',
        soon: 'จะเปิดให้บริการเร็วๆ นี้...',
        insufficient: 'ยอดเงินไม่เพียงพอ',
        quotaInsufficient: 'โควต้าไม่เพียงพอ',
        amountError: 'จำนวนเงินผิดพลาด',
        minAmountError: 'ขั้นต่ำ',
        redeemed: 'คำสั่งซื้อไม่มีอยู่หรือถูกไถ่ถอนแล้ว',
        enter: 'ยืนยัน',
        congratulation: 'ขอแสดงความยินดีที่คุณทำการรวมสำเร็จ',
        walletInsufficient: 'ยอดเงินในกระเป๋าเงินไม่เพียงพอ',
        copySuccess: 'คัดลอกสำเร็จ'
    },
    header: {
        link: 'เชื่อมต่อกระเป๋าเงิน',
        home: 'หน้าหลัก',
        lp: 'สเตกกิ้ง',
        award: 'รางวัล',
        solfi: 'SOLFI',
        user: 'ของฉัน',
        ido: 'IDO',
        inc: '© 2024 SolFi Inc. สงวนลิขสิทธิ์.',
        bind: 'เชื่อมโยง',
        bindPla: 'กรุณาใส่ที่อยู่ผู้แนะนำ',
        bindTitle: 'โปรดเชื่อมโยงที่อยู่ผู้แนะนำ'
    },
    home:{
        launchTitle: 'Sol.fi คือ',
        launchTitle1: 'ห้องสมุดกลยุทธ์ DeFi อัตโนมัติ',
        launchTitle2: 'มุ่งเน้นการให้บริการทางการเงินแบบกระจายศูนย์ที่ง่ายและมีประสิทธิภาพสำหรับผู้ใช้บนบล็อกเชน Solana',
        launchDesc: 'เรามุ่งมั่นที่จะสร้างระบบนิเวศที่เปิดกว้างและหลากหลาย ทำให้ผู้ใช้สามารถใช้ทรัพย์สิน SOL ของพวกเขาได้อย่างง่ายดายเพื่อเข้าร่วมกิจกรรมการสเตกกิ้งและการขุดสภาพคล่องที่หลากหลาย เพื่อการเติบโตของผลตอบแทนที่มั่นคง',
        but1: 'สเตกกิ้ง',
        but2: 'เอกสารทางเทคนิค',
        diversifiedTitle0: 'ง่าย',
        diversifiedTitle10: 'หลากหลาย',
        diversifiedDesc0: 'Sol.fi มอบโซลูชันครบวงจรสำหรับการสเตกกิ้ง SOL และการซื้อขายแบบกระจายศูนย์ ทำให้ผู้ใช้สามารถเข้าร่วมกิจกรรม DeFi ที่หลากหลายได้โดยไม่ต้องมีการดำเนินการที่ซับซ้อน',
        diversifiedTitle1: 'กระจายศูนย์',
        diversifiedTitle11: 'โปรโตคอลการเงิน',
        diversifiedDesc1: 'ในฐานะที่เป็นส่วนหนึ่งของบล็อกเชน Solana Sol.fi สร้างโปรโตคอลการเงินที่กระจายศูนย์อย่างเต็มที่ ทำให้มั่นใจได้ว่าทรัพย์สินของผู้ใช้ปลอดภัยและเพลิดเพลินกับการทำธุรกรรมและการสเตกกิ้งที่มีต้นทุนต่ำ',
        diversifiedTitle2: 'สนับสนุนหลายโปรโตคอล',
        diversifiedTitle12: 'การสนับสนุน',
        diversifiedDesc2: 'พูลสภาพคล่องของ Sol.fi ปัจจุบันถูกสเตกกิ้งในโปรโตคอลที่มีชื่อเสียงเช่น Jupiter, Raydium และ Orca ในอนาคตจะมีการเพิ่มโปรโตคอล DeFi ที่มีคุณภาพมากขึ้น เพื่อให้ผู้ใช้มีทางเลือกในการลงทุนที่กว้างขึ้นและโอกาสในการรับผลตอบแทน',
        benefit: 'ประโยชน์หลัก',
        benefitTitle0: 'กำไรสูง',
        benefitText0: 'ผู้ใช้สามารถได้รับกำไรสูงโดยการสเตกกิ้งทรัพย์สิน SOL ของพวกเขาเพิ่มมูลค่าของทรัพย์สิน',
        benefitTitle1: 'การลงทุนที่หลากหลาย',
        benefitText1: 'Sol.fi ทำให้ผู้ใช้สามารถเข้าร่วมในการสเตกกิ้งและการขุดสภาพคล่องในที่เดียว ลดความเสี่ยงด้วยการกระจายการลงทุน',
        benefitTitle2: 'ระบบนิเวศที่เปิดกว้าง',
        benefitText2: 'ในฐานะที่เป็นส่วนหนึ่งของระบบนิเวศที่เปิดกว้าง Sol.fi จะขยายและเพิ่มโปรโตคอล DeFi ที่ยอดเยี่ยมมากขึ้นอย่างต่อเนื่อง ให้บริการทางการเงินและโอกาสในการรับผลตอบแทนที่หลากหลายแก่ผู้ใช้',
        friend: 'พันธมิตร',
        friendDesc: 'กลยุทธ์ของพูลสเตกกิ้งของ Sol.fi ได้รับการจัดหาโดย Seven Seas ซึ่งเป็นหนึ่งในผู้สร้างพูลสเตกกิ้งชั้นนำในพื้นที่ DeFi ด้วยการร่วมมือกับ Seven Seas Sol.fi สามารถมอบบริการสเตกกิ้งที่ปลอดภัยและเชื่อถือได้แก่ผู้ใช้ และปรับปรุงกลยุทธ์สเตกกิ้งอย่างต่อเนื่องเพื่อให้ได้ผลตอบแทนที่สูงขึ้น ผ่าน Sol.fi เราหวังว่าจะมีส่วนร่วมในการพัฒนาระบบนิเวศของ Solana โดยให้บริการทางการเงินที่มีประสิทธิภาพและปลอดภัยแก่ผู้ใช้ ทำให้คนมากขึ้นได้สัมผัสกับความสะดวกและนวัตกรรมที่เทคโนโลยีบล็อกเชนนำมา'
    },
    pay: {
        desc: 'รับประกันอนาคตของคุณบน Solana – กระเป๋าเงินที่คุณไว้วางใจได้ ทำธุรกรรมได้รวดเร็วราวกับสายฟ้าแลบ!',
        days: 'วัน',
        hours: 'ชั่วโมง',
        minutes: 'นาที',
        seconds: 'วินาที',
        android: 'ดาวน์โหลด Android',
        ios: 'ดาวน์โหลดใน App Store'
    },
    lp:{
        title: 'สเตก SOL ให้เร็วขึ้น',
        title1: 'SolFi ทำให้การสเตก SOL ง่ายขึ้น มอบคุณค่าและผลตอบแทนที่ยั่งยืนแก่ผู้ลงทุนและสมาชิกชุมชน',
        stackDesc: 'SolFi ทำให้การสเตก SOL ง่ายขึ้น มอบคุณค่าและผลตอบแทนที่ยั่งยืนแก่ผู้ลงทุนและสมาชิกชุมชน',
        stackList1: 'การสเตกของฉัน',
        stackList2: 'ที่ได้รับ',
        stackList3: 'ได้รับผ่านการทบต้น',
        lp: 'สเตกกิ้ง',
        underway: 'กำลังสเตกกิ้ง',
        earn: 'รายได้ที่ได้รับ',
        yet: 'รายได้ที่ถอนได้',
        redeem: 'ไถ่ถอน',
        yetBut: 'ถอน',
        accredit: 'อนุมัติ',
        record: 'ประวัติการสเตกกิ้ง',
        tokenTitle: 'รายได้จากการทบต้น',
        tokenDesc: 'รายได้จากการสเตกกิ้งที่ไม่ถูกถอนได้จะมีรายได้จากการทบต้น และจะแจกจ่ายโทเค็น SOLFI ในอัตรา 1 SOLFI = 1 SOL โทเค็น SolFi (SOLFI) จะเป็นโทเค็นที่ทำหน้าที่ในแพลตฟอร์มทั้งหมด ทำให้ผู้ใช้สามารถเข้าร่วมในการบริการ AI ที่ผสานรวมและการจัดการชุมชน และจะมีมูลค่ามากในอนาคต',
        tokenList1: 'สามารถได้รับผลตอบแทนทบต้นหรือไม่',
        tokenList2: 'ได้รับรายได้จากการทบต้น',
        tokenList3: 'รายได้จากการทบต้นที่ถอนได้',
        yes: 'ใช่',
        no: 'ไม่ใช่',
        back: 'กลับไปที่สเตกกิ้ง',
        money: 'จำนวนเงิน',
        timeStart: 'เวลาที่เริ่มสเตกกิ้ง',
        timeEnd: 'เวลาหมดอายุ',
        cancel: 'ยกเลิกการต่ออายุอัตโนมัติ',
        more: 'เพิ่มเติม...',
        max: 'สูงสุด',
        quotaRemain: 'ยอดคงเหลือ',
        usable: 'สามารถสเตกกิ้งได้',
        redeemed: 'ไถ่ถอนแล้ว',
        redeeming: 'กำลังไถ่ถอน...',
        staking: 'กำลังสเตกกิ้ง...'
    },
    award:{
        communityDesc: 'สมาชิกชุมชนที่มีส่วนร่วมในการพัฒนาชุมชนจะได้รับรางวัลพิเศษจากชุมชน',
        communityList1: 'รางวัลที่ได้รับ',
        communityList2: 'ถอนแล้ว',
        communityList3: 'สามารถถอนได้',
        communityBut: 'ถอน',
        record: "บันทึกการให้รางวัล",
        listType1: "รางวัลแนะนำโดยตรง",
        listType2: "รางวัลทีม",
        listType3: "รางวัลระดับเดียวกัน",
        listType4: "รางวัลโบนัส"
    },
    top: {
        tokenDesc: "SolFi Token(SOLFI)จะทำหน้าที่เป็นโทเค็นของแพลตฟอร์มทั้งหมด ใช้เข้าร่วมบริการ AI และการกำกับดูแลชุมชน และมีคุณค่าในอนาคต",
        price: "ราคา",
        allAmount: "อุปทานทั้งหมด",
        market: "มูลค่าตลาด",
        my: "SOLFI ของฉัน",
        balance: "ยอดคงเหลือในกระเป๋าเงิน",
        buy: "แลกเปลี่ยน",
        approve: "อนุมัติ SOL",
        approveTop: "อนุมัติ SOLFI",
        earn: "ได้รับแล้ว",
        getYet: "สามารถรับได้",
        get: "รับ"
    },
    ido: {
        turn: "รอบปัจจุบัน",
        all: "ปริมาณ IDO ทั้งหมด",
        price: "ราคา",
        limit: "วงเงินการจองซื้อ",
        ps: "วิธีการปลดล็อค: ล็อค [] เดือน ปลดล็อคเป็นระยะเวลา 12 เดือน",
        my: "วงเงินของฉัน",
        nobuy: "ยังไม่ได้จองซื้อ",
        balance: "ยอดคงเหลือ",
        custom: "คาดว่าจะได้รับ",
        buy: "จองซื้อตอนนี้",
        buyYet: "จองซื้อแล้ว",
        record: "บันทึกการจองซื้อ",
        buyTrun: "รอบการจองซื้อ",
        buyUsdt: "จองซื้อ SOL",
        getTop: "รับ SOLFI",
        unlock: "เวลาปลดล็อคครั้งถัดไป",
        unlockYet: "ปลดล็อค SOLFI แล้ว",
        claimable: "สามารถรับ SOLFI ได้",
        get: "รับ",
        nodataText: "ไม่มีบันทึกการจองซื้อ"
    },
    user: {
        desc: "แชร์ SolFi ช่วยเหลือผู้ที่ต้องการความช่วยเหลือในด้านบล็อกเชนและได้รับผลตอบแทนที่ดี",
        joinTime: "เวลาลงทะเบียน",
        memberTeam: "สมาชิกทีม",
        memberDirect: "สมาชิกแนะนำโดยตรง",
        teamLp: "การวางเดิมพันทีม",
        myLink: "ลิงค์เชิญของฉัน",
        myTeam: "ทีมของฉัน",
        personage: "การวางเดิมพันส่วนบุคคล",
        cur: "ปัจจุบัน",
        lookDown: "ดูระดับถัดไป",
        copy: "คัดลอก",
        info: "ข้อมูลส่วนบุคคล"
    },
    idos:{
        title: 'เศรษฐกิจโทเคน SolFi',
        total: 'ปริมาณรวม',
        type1: 'รอบ Seed',
        type2: 'รอบพรีเซล',
        type1Time: '3 ธันวาคม 2024 20:00 น.',
        price: 'ราคา',
        amount: 'จำนวน',
        unit: '{amount} ชิ้น',
        type2Time: '4 ธันวาคม 2024 20:00 น.',
        advantageTitle1: 'วิธีการปล่อย',
        advantageDesc1: '10% จะปล่อยใน 24 ชั่วโมงแรก และปล่อยเพิ่ม 10% ทุกสัปดาห์',
        advantageTitle2: 'กฎเกณฑ์',
        advantageDesc2: 'สิ้นสุดเมื่อโควตาเต็ม',
        advantageTitle3: 'กลไกการปล่อย',
        advantageDesc3: 'ข้อจำกัดสำหรับ KOL ชุมชนหลัก: 2 SOL (ประมาณ 500 USDT)',
        ido: 'ที่อยู่รับเงินทางการ IDO (ที่อยู่ Solana)',
        descTitle1: 'ชุมชน 40%',
        desc1: 'เงินทุนใช้สำหรับจัดกิจกรรมต่างๆ เพื่อให้ชุมชนมีชีวิตชีวาและมีส่วนร่วม',
        descTitle2: 'ทีมสนับสนุน 20%',
        desc2: 'สนับสนุนทรัพยากรเพื่อให้ SolFi ทำงานได้ราบรื่นและพัฒนาอย่างต่อเนื่อง',
        descTitle3: 'พูลสภาพคล่อง 20%',
        desc3: 'รักษาพูลสภาพคล่องที่แข็งแกร่ง เพื่อให้การซื้อขายราบรื่นและมีประสิทธิภาพ',
        descTitle4: 'กิจกรรมชุมชน 10%',
        desc4: 'เงินทุนใช้สำหรับจัดกิจกรรมที่น่าสนใจ เพื่อให้ชุมชนมีชีวิตชีวาและมีส่วนร่วม',
        descTitle5: 'กองทุน SolFi 5%',
        desc5: 'กองทุนสำหรับโครงการพิเศษและเป้าหมายระยะยาว แยกจากกองทุนพัฒนาระบบนิเวศ',
        descTitle6: 'กองทุนพัฒนาระบบนิเวศ SolFi 5%',
        desc6: 'สนับสนุนโครงการในอนาคตและนวัตกรรม เพื่อให้ระบบนิเวศเติบโตและขยายตัว'
    }
}
export default th;