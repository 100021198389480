const zh = {
    alert:{
        reLogin: '地址发生改变，请重新登录',
        copied: '复制成功',
        connectWallet: '请连接钱包',
        notBound: '未绑定推荐人地址，请绑定',
        inputParent: '请输入推荐人地址',
        addressError: '请输入正确的钱包地址',
        parentError: '推荐人地址错误，请重新输入',
        boundSuccess: '绑定成功',
        boundFailed: '绑定失败，请重试',
        success: '成功',
        failed: '失败',
        soon: '即将开放...',
        insufficient: '余额不足',
        quotaInsufficient: '额度不足',
        amountError: '金额错误',
        minAmountError: '最低',
        redeemed: '订单不存在或已经赎回',
        enter: '确定',
        congratulation: '恭喜您合成',
        walletInsufficient: '钱包余额不足',
        copySuccess: '复制成功'
    },
    header: {
        link: '连接钱包',
        home: '首页',
        lp: '质押',
        award: '奖励',
        solfi: 'SOLFI',
        user: '我的',
        ido: 'IDO',
        inc: '© 2024 SolFi Inc. 保留所有权利。',
        bind: '绑定',
        bindPla: '请输入邀请人地址',
        bindTitle: '请绑定邀请人地址'
    },
    home:{
        launchTitle: 'Sol.fi 是一个',
        launchTitle1: '自动化 DeFi 策略库',
        launchTitle2: '旨在为 Solana 区块链上的用户提供简单而高效的去中心化金融服务。',
        launchDesc: '我们致力于打造一个开放、多样化的生态系统，让用户可以轻松地利用他们的 SOL 资产参与各种质押和流动性挖矿活动，从而实现稳健的收益增长。',
        but1: '质押',
        but2: '白皮书',
        diversifiedTitle0: '简单',
        diversifiedTitle10: '多样化',
        diversifiedDesc0: 'Sol.fi 提供了 SOL 质押和去中心化交易的一体化解决方案，使用户无需繁琐的操作即可参与多样化的 DeFi 活动。',
        diversifiedTitle1: '去中心化',
        diversifiedTitle11: '金融协议',
        diversifiedDesc1: '作为 Solana 区块链上的一部分，Sol.fi 打造了一个完全去中心化的金融协议，确保用户的资产安全并享受低成本的交易和质押体验。',
        diversifiedTitle2: '多协议',
        diversifiedTitle12: '支持',
        diversifiedDesc2: 'Sol.fi 的流动性矿池目前在 Jupiter、Raydium 和 Orca 等知名协议上进行质押，未来还将陆续加入更多优质的 DeFi 协议，为用户提供更广泛的投资选择和收益机会。',
        benefit: '主要收益',
        benefitTitle0: '高额利润',
        benefitText0: '用户可以通过质押 SOL 资产来赚取高额利润，实现资产增值。',
        benefitTitle1: '多样化投资',
        benefitText1: 'Sol.fi 让用户可以一站式参与质押和流动性挖矿，实现资金的多样化投资，降低风险。',
        benefitTitle2: '开放式生态系统',
        benefitText2: '作为开放式生态系统的一部分，Sol.fi 将不断扩展并加入更多优秀的 DeFi 协议，为用户提供更广泛的金融服务和收益机会。',
        friend: '合作伙伴',
        friendDesc: 'Sol.fi 的质押矿池策略由 Seven Seas 提供，他们是 DeFi 领域顶级的质押矿池建设者之一。通过与 Seven Seas 的合作，Sol.fi 能够为用户提供安全可靠的质押服务，并持续优化质押策略以实现更高的收益。通过 Sol.fi，'
         + '我们希望为 Solana 生态系统的发展做出贡献，为用户提供高效、安全的去中心化金融服务，让更多人能够享受到区块链技术所带来的便利和创新。'
    },
    pay:{
        desc: '在 Solana 上保障您的未来 – 您可以信赖的钱包，可实现闪电般的快速交易！',
        days: '天',
        hours: '时',
        minutes: '分',
        seconds: '秒',
        android: 'Android下载',
        ios: 'APP Store下载'
    },
    lp:{
        title: 'Stack SOL faster',
        title1: 'SolFi将SOL质押变得更简单，为投资者和社区成员提供持续的价值和可观的回报。',
        stackDesc: 'SolFi将SOL质押变得更简单，为投资者和社区成员提供持续的价值和可观的回报。',
        stackList1: '我的质押',
        stackList2: '已赚取',
        stackList3: '已复利获得',
        lp: '质押',
        underway: '质押中',
        earn: '已赚取收益',
        yet: '可提取收益',
        redeem: '赎回',
        yetBut: '提取',
        accredit: '授权',
        record: '质押记录',
        tokenTitle: '复利收益',
        tokenDesc: '不提取质押产生的收益将产生复利收益，按1SOLFI = 1SOL配发SOLFI代币。SolFi Token(SOLFI)将作为整个平台的功能代币，可参与体验集成AI服务和社区治理，在未来将极具价值。',
        tokenList1: '是否可享受复利',
        tokenList2: '已获得复利收益',
        tokenList3: '可提取复利收益',
        yes: '是',
        no: '否',
        back: '返回质押',
        money: '金额',
        timeStart: '质押时间',
        timeEnd: '到期时间',
        cancel: '取消自动续期',
        more: '更多...',
        max: '最大',
        quotaRemain: '余额',
        usable: '可质押',
        redeemed: '已赎回',
        redeeming: '赎回中...',
        staking: '质押中...'
    },
    award:{
        communityDesc: '为社区发展做出贡献的社区成员，将获得社区专项奖励。',
        communityList1: '已获取奖励',
        communityList2: '已提取',
        communityList3: '可提取',
        communityBut: '提取',
        record: '奖励记录',
        listType1: '直推奖励',
        listType2: '团队奖励',
        listType3: '平级奖励',
        listType4: '分红奖励'
    },
    top:{
        tokenDesc: 'SolFi Token(SOLFI)将作为整个平台的功能代币，可参与体验集成AI服务和社区治理，在未来将极具价值。',
        price: '价格',
        allAmount: '总供应量',
        market: '市值',
        my: '我的SOLFI',
        balance: '钱包余额',
        buy: 'Swap',
        approve: '批准SOL',
        approveTop: '批准SOLFI',
        earn: '已赚取',
        getYet: '可领取',
        get: '领取'
    },
    ido:{
        turn: '当前轮次',
        all: 'IDO总量',
        price: '价格',
        limit: '认购额度',
        ps: '解锁方式：锁定[]个月后，分12个月线性解锁',
        my: '我的额度',
        nobuy: '未认购',
        balance: '余额',
        custom: '预计获得',
        buy: '立即认购',
        buyYet: '已认购',
        record: '认购记录',
        buyTrun: '认购轮次',
        buyUsdt: '认购SOL',
        getTop: '获得SOLFI',
        unlock: '下一次解锁时间',
        unlockYet: '已解锁SOLFI',
        claimable: '可领取SOLFI',
        get: '领取',
        nodataText: '暂无认购记录'
    },
    user:{
        desc: '分享SolFi，帮助更多需要帮助的人们，在区块链领域赚取可观收益。',
        joinTime: '注册时间',
        memberTeam: '团队成员',
        memberDirect: '直推成员',
        teamLp: '团队质押',
        myLink: '我的邀请链接',
        myTeam: '我的团队',
        personage: '个人质押',
        cur: '当前',
        lookDown: '查看下级',
        copy: '复制',
        info: '个人信息'
    },
    idos:{
        title: 'SolFi 代币经济',
        total: '总量',
        type1: '种子轮',
        type2: '预售轮',
        type1Time: '2024年12月03日 8:00pm',
        price: '价格',
        amount: '名额',
        unit: '{amount}份',
        type2Time: '2024年12月04日 8:00pm',
        advantageTitle1: '释放方式',
        advantageDesc1: '10%将在24小时内释放，另外每周释放10%',
        advantageTitle2: '规则',
        advantageDesc2: '名额打满结束',
        advantageTitle3: '释放机制',
        advantageDesc3: '核心社区KOL限额：2 SOL(约500 USDT)',
        ido: 'IDO官方收款地址(Solana地址)',
        descTitle1: '社区 40%',
        desc1: '资金用于举办各种激动人心的活动保持社区的活力和参与度。',
        descTitle2: '维护团队 20%',
        desc2: '确保我们的热情团队拥有资源使 SolFi平稳运行并不断发展。',
        descTitle3: '流动性池 20%',
        desc3: '维持强大的流动性池，确保交易顺畅高效。',
        descTitle4: '社区活动 10%',
        desc4: '资金用于举办各种激动人心的活动保持社区的活力和参与度。',
        descTitle5: 'SolFi基金 5%',
        desc5: '专门用于特殊项目和长期发展目标与生态发展基金分开。',
        descTitle6: 'SolFi生态发展基金 5%',
        desc6: '旨在推动未来项目和创新，确保生态系统蓬勃发展和扩展。',
    }
}

export default zh;
