const vi = {
    alert:{
        reLogin: 'Địa chỉ đã thay đổi, vui lòng đăng nhập lại',
        copied: 'Sao chép thành công',
        connectWallet: 'Vui lòng kết nối ví',
        notBound: 'Chưa liên kết địa chỉ giới thiệu, vui lòng liên kết',
        inputParent: 'Vui lòng nhập địa chỉ giới thiệu',
        addressError: 'Vui lòng nhập địa chỉ ví chính xác',
        parentError: 'Địa chỉ giới thiệu không đúng, vui lòng nhập lại',
        boundSuccess: 'Liên kết thành công',
        boundFailed: 'Liên kết thất bại, vui lòng thử lại',
        success: 'Thành công',
        failed: 'Thất bại',
        soon: 'Sắp mở...',
        insufficient: 'Số dư không đủ',
        quotaInsufficient: 'Hạn mức không đủ',
        amountError: 'Số tiền không đúng',
        minAmountError: 'Tối thiểu',
        redeemed: 'Đơn hàng không tồn tại hoặc đã được chuộc lại',
        enter: 'Xác nhận',
        congratulation: 'Chúc mừng bạn đã tổng hợp thành công',
        walletInsufficient: 'Số dư ví không đủ',
        copySuccess: 'Sao chép thành công'
    },
    header: {
        link: 'Kết nối ví',
        home: 'Trang chủ',
        lp: 'Đặt cọc',
        award: 'Phần thưởng',
        solfi: 'SOLFI',
        user: 'Của tôi',
        ido: 'IDO',
        inc: '© 2024 SolFi Inc. Bảo lưu mọi quyền.',
        bind: 'Liên kết',
        bindPla: 'Vui lòng nhập địa chỉ người mời',
        bindTitle: 'Vui lòng liên kết địa chỉ người mời'
    },
    home:{
        launchTitle: 'Sol.fi là một',
        launchTitle1: 'thư viện chiến lược DeFi tự động',
        launchTitle2: 'nhằm cung cấp các dịch vụ tài chính phi tập trung đơn giản và hiệu quả cho người dùng trên blockchain Solana.',
        launchDesc: 'Chúng tôi cam kết xây dựng một hệ sinh thái mở và đa dạng, giúp người dùng dễ dàng sử dụng tài sản SOL của họ để tham gia vào các hoạt động đặt cọc và khai thác thanh khoản khác nhau, nhằm đạt được tăng trưởng lợi nhuận ổn định.',
        but1: 'Đặt cọc',
        but2: 'Sách trắng',
        diversifiedTitle0: 'Đơn giản',
        diversifiedTitle10: 'Đa dạng',
        diversifiedDesc0: 'Sol.fi cung cấp giải pháp tích hợp cho việc đặt cọc SOL và giao dịch phi tập trung, giúp người dùng tham gia các hoạt động DeFi đa dạng mà không cần thao tác phức tạp.',
        diversifiedTitle1: 'Phi tập trung',
        diversifiedTitle11: 'Giao thức tài chính',
        diversifiedDesc1: 'Là một phần của blockchain Solana, Sol.fi đã tạo ra một giao thức tài chính hoàn toàn phi tập trung, đảm bảo an toàn cho tài sản của người dùng và trải nghiệm giao dịch và đặt cọc với chi phí thấp.',
        diversifiedTitle2: 'Hỗ trợ',
        diversifiedTitle12: 'đa giao thức',
        diversifiedDesc2: 'Hiện tại, hồ bơi khai thác thanh khoản của Sol.fi đang đặt cọc trên các giao thức nổi tiếng như Jupiter, Raydium và Orca, và trong tương lai sẽ thêm nhiều giao thức DeFi chất lượng khác, cung cấp cho người dùng nhiều lựa chọn đầu tư và cơ hội lợi nhuận hơn.',
        benefit: 'Lợi ích chính',
        benefitTitle0: 'Lợi nhuận cao',
        benefitText0: 'Người dùng có thể kiếm được lợi nhuận cao bằng cách đặt cọc tài sản SOL, gia tăng giá trị tài sản.',
        benefitTitle1: 'Đầu tư đa dạng',
        benefitText1: 'Sol.fi cho phép người dùng tham gia đặt cọc và khai thác thanh khoản tại một nơi, thực hiện đầu tư đa dạng hóa vốn, giảm rủi ro.',
        benefitTitle2: 'Hệ sinh thái mở',
        benefitText2: 'Là một phần của hệ sinh thái mở, Sol.fi sẽ liên tục mở rộng và thêm nhiều giao thức DeFi xuất sắc khác, cung cấp cho người dùng nhiều dịch vụ tài chính và cơ hội lợi nhuận hơn.',
        friend: 'Đối tác',
        friendDesc: 'Chiến lược hồ bơi khai thác của Sol.fi được cung cấp bởi Seven Seas, một trong những nhà xây dựng hồ bơi khai thác hàng đầu trong lĩnh vực DeFi. Thông qua hợp tác với Seven Seas, Sol.fi có thể cung cấp dịch vụ đặt cọc an toàn và tin cậy cho người dùng, và liên tục tối ưu hóa chiến lược đặt cọc để đạt được lợi nhuận cao hơn. Thông qua Sol.fi,'
         + 'chúng tôi hy vọng đóng góp vào sự phát triển của hệ sinh thái Solana, cung cấp dịch vụ tài chính phi tập trung hiệu quả và an toàn cho người dùng, giúp nhiều người hơn có thể tận hưởng những tiện ích và sự đổi mới mà công nghệ blockchain mang lại.'
    },
    pay: {
        desc: 'Bảo vệ tương lai của bạn trên Solana – Ví điện tử đáng tin cậy của bạn, thực hiện giao dịch nhanh như chớp!',
        days: 'ngày',
        hours: 'giờ',
        minutes: 'phút',
        seconds: 'giây',
        android: 'Tải xuống Android',
        ios: 'Tải xuống trên App Store'
    },
    lp:{
        title: 'Đặt cọc SOL nhanh hơn',
        title1: 'SolFi làm cho việc đặt cọc SOL trở nên dễ dàng hơn, cung cấp giá trị bền vững và lợi nhuận đáng kể cho nhà đầu tư và thành viên cộng đồng.',
        stackDesc: 'SolFi làm cho việc đặt cọc SOL trở nên dễ dàng hơn, cung cấp giá trị bền vững và lợi nhuận đáng kể cho nhà đầu tư và thành viên cộng đồng.',
        stackList1: 'Đặt cọc của tôi',
        stackList2: 'Đã kiếm được',
        stackList3: 'Đã được tích lũy',
        lp: 'Đặt cọc',
        underway: 'Đang đặt cọc',
        earn: 'Lợi nhuận đã kiếm được',
        yet: 'Lợi nhuận có thể rút',
        redeem: 'Chuộc lại',
        yetBut: 'Rút',
        accredit: 'Ủy quyền',
        record: 'Lịch sử đặt cọc',
        tokenTitle: 'Lợi nhuận tích lũy',
        tokenDesc: 'Lợi nhuận từ việc đặt cọc không rút sẽ sinh lợi nhuận tích lũy, với tỷ lệ 1SOLFI = 1SOL. SolFi Token (SOLFI) sẽ được sử dụng như token chức năng của toàn bộ nền tảng, có thể tham gia trải nghiệm dịch vụ AI tích hợp và quản lý cộng đồng, trong tương lai sẽ có giá trị cao.',
        tokenList1: 'Có được lợi nhuận tích lũy',
        tokenList2: 'Lợi nhuận tích lũy đã nhận',
        tokenList3: 'Lợi nhuận tích lũy có thể rút',
        yes: 'Có',
        no: 'Không',
        back: 'Quay lại đặt cọc',
        money: 'Số tiền',
        timeStart: 'Thời gian đặt cọc',
        timeEnd: 'Thời gian hết hạn',
        cancel: 'Hủy gia hạn tự động',
        more: 'Thêm...',
        max: 'Tối đa',
        quotaRemain: 'Số dư',
        usable: 'Có thể đặt cọc',
        redeemed: 'Đã chuộc lại',
        redeeming: 'Đang chuộc lại...',
        staking: 'Đang đặt cọc...'
    },
    award:{
        communityDesc: 'Các thành viên cộng đồng có đóng góp cho sự phát triển cộng đồng sẽ nhận được phần thưởng đặc biệt của cộng đồng.',
        communityList1: 'Phần thưởng đã nhận',
        communityList2: 'Đã rút',
        communityList3: 'Có thể rút',
        communityBut: 'Rút',
        record: 'Lịch sử phần thưởng',
        listType1: 'Phần thưởng giới thiệu trực tiếp',
        listType2: 'Phần thưởng nhóm',
        listType3: 'Phần thưởng cấp ngang',
        listType4: 'Phần thưởng chia sẻ'
    },
    top:{
        tokenDesc: 'SolFi Token (SOLFI) sẽ được sử dụng như token chức năng của toàn bộ nền tảng, có thể tham gia trải nghiệm dịch vụ AI tích hợp và quản lý cộng đồng, trong tương lai sẽ có giá trị cao.',
        price: 'Giá',
        allAmount: 'Tổng cung',
        market: 'Giá trị thị trường',
        my: 'SOLFI của tôi',
        balance: 'Số dư ví',
        buy: 'Swap',
        approve: 'Phê duyệt SOL',
        approveTop: 'Phê duyệt SOLFI',
        earn: 'Đã kiếm được',
        getYet: 'Có thể nhận',
        get: 'Nhận'
    },
    ido:{
        turn: 'Lượt hiện tại',
        all: 'Tổng số IDO',
        price: 'Giá',
        limit: 'Hạn mức đăng ký',
        ps: 'Cách mở khóa: Khóa trong [] tháng, sau đó mở khóa dần trong 12 tháng',
        my: 'Hạn mức của tôi',
        nobuy: 'Chưa đăng ký',
        balance: 'Số dư',
        custom: 'Dự kiến nhận được',


        buy: 'Đăng ký ngay',
        buyYet: 'Đã đăng ký',
        record: 'Lịch sử đăng ký',
        buyTrun: 'Lượt đăng ký',
        buyUsdt: 'Đăng ký SOL',
        getTop: 'Nhận SOLFI',
        unlock: 'Thời gian mở khóa tiếp theo',
        unlockYet: 'SOLFI đã mở khóa',
        claimable: 'SOLFI có thể nhận',
        get: 'Nhận',
        nodataText: 'Chưa có lịch sử đăng ký'
    },
    user:{
        desc: 'Chia sẻ SolFi, giúp đỡ nhiều người hơn trong lĩnh vực blockchain kiếm được lợi nhuận đáng kể.',
        joinTime: 'Thời gian đăng ký',
        memberTeam: 'Thành viên nhóm',
        memberDirect: 'Thành viên giới thiệu trực tiếp',
        teamLp: 'Đặt cọc nhóm',
        myLink: 'Liên kết mời của tôi',
        myTeam: 'Nhóm của tôi',
        personage: 'Đặt cọc cá nhân',
        cur: 'Hiện tại',
        lookDown: 'Xem cấp dưới',
        copy: 'Sao chép',
        info: 'Thông tin cá nhân'
    },
    idos:{
        title: 'Nền kinh tế token SolFi',
        total: 'Tổng cộng',
        type1: 'Vòng Seed',
        type2: 'Vòng Pre-sale',
        type1Time: 'Ngày 03 tháng 12 năm 2024, 8:00 PM',
        price: 'Giá',
        amount: 'Số lượng',
        unit: '{amount} phần',
        type2Time: 'Ngày 04 tháng 12 năm 2024, 8:00 PM',
        advantageTitle1: 'Phương thức phát hành',
        advantageDesc1: '10% sẽ được phát hành trong 24 giờ, phần còn lại sẽ phát hành 10% mỗi tuần',
        advantageTitle2: 'Quy tắc',
        advantageDesc2: 'Kết thúc khi đủ số lượng',
        advantageTitle3: 'Cơ chế phát hành',
        advantageDesc3: 'Giới hạn KOL cộng đồng: 2 SOL (khoảng 500 USDT)',
        ido: 'Địa chỉ nhận tiền chính thức IDO (địa chỉ Solana)',
        descTitle1: 'Cộng đồng 40%',
        desc1: 'Quỹ dùng để tổ chức các sự kiện thú vị giữ cho cộng đồng luôn sôi động và tham gia',
        descTitle2: 'Đội ngũ hỗ trợ 20%',
        desc2: 'Đảm bảo đội ngũ nhiệt huyết của chúng tôi có đủ tài nguyên để SolFi hoạt động suôn sẻ và phát triển liên tục',
        descTitle3: 'Quỹ thanh khoản 20%',
        desc3: 'Duy trì quỹ thanh khoản mạnh mẽ để đảm bảo giao dịch diễn ra mượt mà và hiệu quả',
        descTitle4: 'Hoạt động cộng đồng 10%',
        desc4: 'Quỹ dùng để tổ chức các sự kiện thú vị giữ cho cộng đồng luôn sôi động và tham gia',
        descTitle5: 'Quỹ SolFi 5%',
        desc5: 'Dành cho các dự án đặc biệt và mục tiêu phát triển dài hạn, tách biệt với quỹ phát triển hệ sinh thái',
        descTitle6: 'Quỹ phát triển hệ sinh thái SolFi 5%',
        desc6: 'Dành cho việc thúc đẩy các dự án và đổi mới trong tương lai, đảm bảo hệ sinh thái phát triển và mở rộng'
    }
}

export default vi;