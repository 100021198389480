const zh = {
    alert:{
        reLogin: 'The address has changed, please log in again',
        copied: 'Copied',
        connectWallet: 'Please connect wallet',
        notBound: 'The recommender address is not bound, please bind it.',
        inputParent: 'Please enter the recommender\'s address',
        addressError: 'Please enter the correct wallet address',
        parentError: 'The recommender\'s address is wrong, please re-enter it.',
        boundSuccess: 'Binding successful',
        boundFailed: 'Binding failed, please try again',
        success: 'Success',
        failed: 'Failed',
        soon: 'Opening soon...',
        insufficient: 'Insufficient balance',
        quotaInsufficient: 'Insufficient quota',
        amountError: 'Wrong amount',
        minAmountError: 'Lowest',
        redeemed: 'The order does not exist or has been redeemed',
        enter: 'Sure',
        congratulation: 'Congratulations on your synthesis',
        walletInsufficient: 'Insufficient wallet balance',
        copySuccess: 'Copied successfully'
    },
    header: {
        link: 'Connect',
        home: 'Home',
        lp: 'Stake',
        award: 'Awards',
        solfi: 'SOLFI',
        user: 'My',
        ido: 'IDO',
        inc: '© 2024 SolFi Inc. All rights reserved.',
        bind: 'Bind',
        bindPla: 'Please enter the inviter\'s address',
        bindTitle: 'Please bind the inviter\'s address'
    },
    home:{
        launchTitle: 'Sol.fi is an',
        launchTitle1: 'Automated DeFi Policy Library.',
        launchTitle2: 'Intended to provide simple and efficient decentralized financial services for users on the Solana blockchain.',
        launchDesc: 'We are committed to creating an open and diverse ecosystem that allows users to easily utilize their SOL assets to participate in various staking and liquidity mining activities, thereby achieving robust revenue growth.',
        but1: 'Stake',
        but2: 'Whitepaper',
        diversifiedTitle0: 'Simple',
        diversifiedTitle10: ' Diversification',
        diversifiedDesc0: 'Sol.fi offers a comprehensive solution for SOL staking and decentralized trading, enabling users to engage in a range of DeFi activities without going through intricate processes.',
        diversifiedTitle1: 'Decentralization',
        diversifiedTitle11: ' Financial Protocol',
        diversifiedDesc1: 'As a component of the Solana blockchain, Sol.fi has developed a fully decentralized financial protocol to safeguard users\' assets and offer a cost-effective trading and staking experience.',
        diversifiedTitle2: 'Multi protocol',
        diversifiedTitle12: ' Support',
        diversifiedDesc2: 'Sol.fi\'s liquidity mining pool currently stakes on Jupiter, Raydium, and Orca, among other renowned protocols. In the near future, it will continue to integrate more premium DeFi protocols, offering users a broader spectrum of investment options and income opportunities.',
        benefit: 'Main income',
        benefitTitle0: 'High profit',
        benefitText0: 'Users can earn substantial profits and achieve asset appreciation by pledging SOL assets.',
        benefitTitle1: 'Diversified investments',
        benefitText1: 'Sol.fi enables users to engage in both staking and liquidity mining in a convenient and integrated manner, achieving diversified investment of funds and minimizing risks.',
        benefitTitle2: 'Open ecosystem',
        benefitText2: 'As a component of an open ecosystem, Sol.fi will persist in expanding and integrating additional top-tier DeFi protocols, offering users a broader spectrum of financial services and income opportunities.',
        friend: 'Partners',
        friendDesc: 'Sol.fi\'s staking mining pool strategy is powered by Seven Seas, one of the leading staking mining pool developers in the DeFi sector. By collaborating with Seven Seas, Sol.fi offers users secure and reliable staking services and continuously optimizes staking strategies for maximized returns. With Sol.fi, we aim to contribute to the growth of the Solana ecosystem, delivering efficient and secure decentralized financial services, and empowering more people to reap the benefits of blockchain technology.'
    },
    pay:{
        desc: 'Secure your future on Solana – a wallet you can trust with lightning-fast transactions!',
        days: 'Days',
        hours: 'Hours',
        minutes: 'Minutes',
        seconds: 'Seconds',
        android: 'Download for Android',
        ios: 'Download on App Store'
    },
    lp:{
        title: 'Stack SOL faster',
        title1: 'SolFi streamlines the process of SOL staking, ensuring a continuous flow of value and significant returns for both investors and community members.',
        stackDesc: 'SolFi streamlines the process of SOL staking, ensuring a continuous flow of value and significant returns for both investors and community members.',
        stackList1: 'My stake',
        stackList2: 'Earned',
        stackList3: 'Compound',
        lp: 'Stake',
        underway: 'Staking',
        earn: 'Earned',
        yet: 'Extractable',
        redeem: 'Redeem',
        yetBut: 'Claim',
        accredit: 'Approve',
        record: 'Stake Records',
        tokenTitle: 'Compound',
        tokenDesc: 'Failure to redeem the pledge will yield compound interest, resulting in the issuance of SOLFI tokens at a rate of 1SOLFI = 1SOL. The SolFi Token (SOLFI) will function as a utility token for the platform, enabling participation in integrated AI services and community governance, and is expected to become highly valuable in the future.',
        tokenList1: 'Can compound',
        tokenList2: 'Compound',
        tokenList3: 'Claimable',
        yes: 'Yes',
        no: 'No',
        back: 'Return',
        money: 'Amount',
        timeStart: 'Time',
        timeEnd: 'Expiration',
        cancel: 'Cancel renewal',
        more: 'More...',
        max: 'Max',
        quotaRemain: 'Balance',
        usable: 'Stakeable',
        redeemed: 'Redeemed',
        redeeming: 'Redeeming...',
        staking: 'Staking...'
    },
    award:{
        communityDesc: 'Community members who contribute to the development of their communities will receive special community awards.',
        communityList1: 'Rewards',
        communityList2: 'Claimed',
        communityList3: 'Claimable',
        communityBut: 'Claim',
        record: 'Records',
        listType1: 'Directly',
        listType2: 'Team',
        listType3: 'Level',
        listType4: 'Bonus'
    },
    top:{
        tokenDesc: 'SolFi Token (SOLFI) will serve as a functional token for the entire platform, enabling participation in integrated AI services and community governance, and is expected to become highly valuable in the future.',
        price: 'Price',
        allAmount: 'TotalSupply',
        market: 'Cap',
        my: 'My SOLFI',
        balance: 'Balance',
        buy: 'Swap',
        approve: 'Approve SOL',
        approveTop: 'Approve SOLFI',
        earn: 'Awards',
        getYet: 'Claimable',
        get: 'Claim'
    },
    ido:{
        turn: '当前轮次',
        all: 'IDO总量',
        price: '价格',
        limit: '认购额度',
        ps: '解锁方式：锁定[]个月后，分12个月线性解锁',
        my: '我的额度',
        nobuy: '未认购',
        balance: '余额',
        custom: '预计获得',
        buy: '立即认购',
        buyYet: '已认购',
        record: '认购记录',
        buyTrun: '认购轮次',
        buyUsdt: '认购SOL',
        getTop: '获得SOLFI',
        unlock: '下一次解锁时间',
        unlockYet: '已解锁SOLFI',
        claimable: '可领取SOLFI',
        get: '领取',
        nodataText: '暂无认购记录'
    },
    user:{
        desc: 'Sharing SolFi can help more people in need and earn considerable profits in the blockchain field.',
        joinTime: 'Reg Time',
        memberTeam: 'Team',
        memberDirect: 'Invite',
        teamLp: 'Team Stake',
        myLink: 'My invitation link',
        myTeam: 'My Team',
        personage: 'Stake',
        cur: 'Current',
        lookDown: 'Subordinate',
        copy: 'Copy',
        info: 'Personal'
    },
    idos:{
        title: 'SolFi Token Economy',
        total: 'Total Supply',
        type1: 'Seed Round',
        type2: 'Presale Round',
        type1Time: 'December 03, 2024, 8:00 PM',
        price: 'Price',
        amount: 'Quota',
        unit: '{amount} slots',
        type2Time: 'December 04, 2024, 8:00 PM',
        advantageTitle1: 'Release Method',
        advantageDesc1: '10% released within 24 hours, then 10% weekly',
        advantageTitle2: 'Rules',
        advantageDesc2: 'Ends when quota is filled',
        advantageTitle3: 'Release Mechanism',
        advantageDesc3: 'Core community KOL limit: 2 SOL (approximately 500 USDT)',
        ido: 'IDO official deposit address(Solana address)',
        descTitle1: 'Community 40%',
        desc1: 'Funds will be used to host exciting events to keep the community vibrant and engaged.',
        descTitle2: 'Maintenance Team 20%',
        desc2: 'Ensuring our dedicated team has the resources to keep SolFi running smoothly and growing.',
        descTitle3: 'Liquidity Pool 20%',
        desc3: 'Maintaining a strong liquidity pool to ensure smooth and efficient trading.',
        descTitle4: 'Community Events 10%',
        desc4: 'Funds will be used to host exciting events to keep the community vibrant and engaged.',
        descTitle5: 'SolFi Fund 5%',
        desc5: 'Dedicated to special projects and long-term development goals, separate from the Ecosystem Development Fund.',
        descTitle6: 'SolFi Ecosystem Development Fund 5%',
        desc6: 'Aimed at fostering future projects and innovation, ensuring the ecosystem thrives and expands.',
    }
}

export default zh;
